function setEqualHeight() {
    current_breakpoint_num = getNumSkelSize();
    /*
    if (current_breakpoint_num > 2) {
        $(".kontakt-in-mnenja .grid-element-content").matchHeight({
            target : $("#kontakt-visina")
        });
    } else {
        $(".kontakt-in-mnenja .grid-element-content").matchHeight({remove: true});
    }
    */
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state-fixed').on("change",function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 50,
        scroll_limit = 130,
        initial_height = 80,
        target_height = 60,
        initial_logo_height = 150,
        target_logo_height_1 = 100,
        target_logo_height = 60,
        initial_padding = 30,
        target_padding = 5,
        initial_margin_top = 0,
        target_margin_top = -50,
        margin,
        logo_height,
        logo_top,
        padding,
        height,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit_1 = 50;
            scroll_limit = 130;
            initial_height = 80;
            target_height = 60;
            initial_logo_height = 150,
            target_logo_height_1 = 100,
            target_logo_height = 60,
            initial_padding = 30;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
        case "xxlarge":
            scroll_limit_1 = 50;
            scroll_limit = 130;
            initial_height = 80;
            target_height = 60;
            initial_logo_height = 150,
            target_logo_height_1 = 100,
            target_logo_height = 60,
            initial_padding = 30;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
        case "large":
            scroll_limit_1 = 40;
            scroll_limit = 120;
            initial_height = 80;
            target_height = 60;
            initial_logo_height = 130,
            target_logo_height_1 = 80,
            target_logo_height = 60,
            initial_padding = 30;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "medium":
            scroll_limit_1 = 40;
            scroll_limit = 90;
            initial_height = 50;
            target_height = 50;
            initial_logo_height = 100,
            target_logo_height_1 = 50,
            target_logo_height = 50,
            initial_padding = 20;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "small":
            scroll_limit_1 = 40;
            scroll_limit = 80;
            initial_height = 40;
            target_height = 40;
            initial_logo_height = 90,
            target_logo_height_1 = 40,
            target_logo_height = 40,
            initial_padding = 15;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "xsmall":
            scroll_limit_1 = 40;
            scroll_limit = 80;
            initial_height = 40;
            target_height = 40;
            initial_logo_height = 40,
            target_logo_height_1 = 40,
            target_logo_height = 40,
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        default:
            scroll_limit_1 = 50;
            scroll_limit = 130;
            initial_height = 80;
            target_height = 60;
            initial_padding = 30;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -50;
            break;
    }
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            margin_top = initial_margin_top
            padding = initial_padding;
            logo_height = initial_logo_height;
            logo_top = 0;
            height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            margin_top = initial_margin_top - $(window).scrollTop();
            padding = initial_padding - (initial_padding - target_padding) * ($(window).scrollTop() / (scroll_limit_1));
            logo_height = initial_logo_height - (initial_logo_height - target_logo_height_1) * ($(window).scrollTop() / scroll_limit_1);
            logo_top = $(window).scrollTop();
            height = initial_height;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = target_padding;
            logo_height = target_logo_height_1 - (target_logo_height_1 - target_logo_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            height = initial_height - (initial_height - target_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            logo_top = scroll_limit_1;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = target_padding;
            logo_height = target_logo_height;
            logo_top = scroll_limit_1;
            height = target_height;
        }
        $("#logo").css("top", logo_top + "px");
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        $("#logo").css("height", logo_height + "px");
        $("#header-main-left").css("height", height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        $("#logo").css("height", "");
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");

        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
        }
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
